var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.userlist,"item-key":"id","show-select":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.status",fn:function(){return [_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrendingUp)+" ")])]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{class:("v-avatar-light-bg " + (item.statusColor) + "--text"),attrs:{"size":"30","color":item.statusColor}},[_c('v-icon',{attrs:{"size":"18","color":item.statusColor}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:("v-avatar-light-bg " + (item.avatarColor) + "--text"),attrs:{"color":item.avatar ? '' : item.avatarColor,"size":"30"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.fullName.slice(0,2).toUpperCase()))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary  font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.fullName))]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item.email))])])],1)]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.total)+" ")]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [(typeof item.balance === 'number')?_c('span',[_vm._v(" $"+_vm._s(item.balance))]):_c('v-chip',{class:("v-chip-light-bg " + (_vm.chipColor[item.balance]) + "--text font-weight-semibold"),attrs:{"small":"","color":_vm.chipColor[item.balance]}},[_vm._v(" "+_vm._s(item.balance)+" ")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }